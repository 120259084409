<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Solicitudes</h1>
    </div>
    <br />

    <DataGrid :config="dataGridConfig" :data="solicitudes" :select="seleccionar" @actions="dataGridActions">
      <div class="row mb-4">
        <div class="col-sm-2"><button class="btn warning-btn" @click="obtener_solicitudes">Recargar</button></div>
        <div class="col-sm-2"><button class="btn secondary-btn" @click="ver_detalles">Verificar solicitud</button></div>
      </div>
    </DataGrid>

    <Detalle v-if="modal_detalle" :solicitud_sel="solicitud" @close="cerrar_modal_detalle" />
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV2'
  import Detalle from './Solicitud/Detalle'

  import api from '@/apps/metricas/api/solicitudes'

  export default {
    components: {
      DataGrid, Detalle
    }
    ,data: function() {
      return {
        dataGridConfig: {
          name: 'solicitudes'
          ,cols: {
            solicitud_id: 'Solicitud'
            ,ultimo_sla: {
              text: 'Última línea temporal'
              ,ordeable: false
            }
            ,tmp_01: {
              text: 'Etapa'
              ,ordeable: false
            }
            ,tmp_02: {
              text: 'Usuario'
              ,ordeable: false
            }
            ,tmp_03: {
              text: 'Ingreso a etapa' 
              ,ordeable: false
            }
            ,estatus: 'Estatus'
          }
          ,paginator: {
            pagina_actual: 1
            ,total_registros: 1
            ,registros_por_pagina: 20
          }
          ,mutators: {
            estatus: function(val) {
              return val == 1 ? 'Activo' : 'Inactivo';
            }
            ,ultimo_sla: function(val) {
              return val ? val.linea_temporal : 'NA';
            }
            ,tmp_01: function(val, row) {
              return row.ultimo_sla ? row.ultimo_sla.etapa.etapa : 'NA';
            }
            ,tmp_02: function(val, row, vue) {
              return row.ultimo_sla ? row.ultimo_sla.usuario : 'NA';
            }
            ,tmp_03: function(val, row, vue) {
              return row.ultimo_sla ? vue.$moment(row.ultimo_sla.created_at).locale('es').format('ddd DD MMM YYYY HH:mm:ss') : 'NA';
            }
          }
        }
        ,options: {
          page: 1
          ,order_col: 'id'
          ,order_dir: 'desc'
          ,limit: 20
          ,filters: []
        }
        ,seleccionar: false
        ,seleccionadas: []
        ,solicitudes: []
        ,modal_detalle: false
        ,solicitud: null
      }
    }
    ,methods: {
      dataGridActions: function(tipo, data) {
        if (tipo == 'options') {
          this.options = data;
          this.obtener_solicitudes();
        }else {
          this.seleccionadas = data;
          this.seleccionar = true;
        }
      }
      ,obtener_solicitudes: async function() {
        try {
          let res = (await api.obtener_solicitudes(this.options)).data

          this.solicitudes = res.data;
          this.dataGridConfig.paginator.pagina_actual = res.current_page;
          this.dataGridConfig.paginator.total_registros = res.total;
          this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,ver_detalles: function() {
        if (this.seleccionadas.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar una solicitud','error','alert');

        this.solicitud = this.seleccionadas[0];
        this.modal_detalle = true;
      }
      ,cerrar_modal_detalle: function() {
        this.modal_detalle = false;
        this.seleccionar = false;
      }
    }
  }
</script>